import BaseComponent from '../../../components/base';
import { ALIGN_ITEMS, COLORS, CREDITS_FLOW_MODAL_TYPE, FLEX_DIRECTION, FONT_FACE, JUSTIFY_CONTENT, SCREEN_SIZE, TEXT_ALIGN, VERTICAL_ALIGN, } from '../../../constants';
import { restrictedAuthorized, restrictedUnauthorized, unrestrictedAuthorized, unrestrictedUnauthorized, } from './templates';
import ModalManager, { ModalTypes } from '../../../lib/ModalManager';
const templateMap = {
    [CREDITS_FLOW_MODAL_TYPE.UNRESTRICTED_UNAUTHORIZED]: unrestrictedUnauthorized,
    [CREDITS_FLOW_MODAL_TYPE.UNRESTRICTED_AUTHORIZED]: unrestrictedAuthorized,
    [CREDITS_FLOW_MODAL_TYPE.RESTRICTED_UNAUTHORIZED]: restrictedUnauthorized,
    [CREDITS_FLOW_MODAL_TYPE.RESTRICTED_AUTHORIZED]: restrictedAuthorized,
};
export default class CreditsFlowModal extends BaseComponent {
    constructor() {
        super(...arguments);
        this.title = '';
        this._focusIndex = 0;
        this._focusMap = [];
    }
    static _template() {
        return {
            rect: true,
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            color: COLORS.black,
            Title: {
                y: 219,
                x: (w) => w / 2,
                mountX: 0.5,
                text: {
                    textAlign: TEXT_ALIGN.center,
                    fontSize: 56,
                    fontFace: FONT_FACE.light,
                    textColor: COLORS.white,
                    verticalAlign: VERTICAL_ALIGN.middle,
                    lineHeight: 70,
                    letterSpacing: -0.25,
                    text: this.bindProp('title'),
                    wordWrap: true,
                    wordWrapWidth: 1051,
                },
            },
            Content: {
                w: 1439,
                h: 478,
                x: (w) => w / 2,
                mountX: 0.5,
                flex: {
                    direction: FLEX_DIRECTION.row,
                    justifyContent: JUSTIFY_CONTENT.spaceBetween,
                    alignItems: ALIGN_ITEMS.center,
                },
                flexItem: false,
                y: 402,
            },
        };
    }
    set modalData(data) {
        this._modalData = data;
        this.setModalType();
    }
    setModalType() {
        var _a, _b;
        if (this._type === this._modalData.type)
            return;
        if ((_b = (_a = this._content) === null || _a === void 0 ? void 0 : _a.childList) === null || _b === void 0 ? void 0 : _b.length)
            this._content.childList.clear();
        this._type = this._modalData.type;
        this._patchTemplate();
    }
    _init() {
        const title = this.tag('Title');
        if (title) {
            title.on('txLoaded', (texture) => {
                var _a, _b;
                const linesLength = ((_b = (_a = texture.source) === null || _a === void 0 ? void 0 : _a.renderInfo) === null || _b === void 0 ? void 0 : _b.lines.length) || 0;
                const textureGain = (title.text.lineHeight - title.text.fontSize) / 2;
                const y = linesLength > 1 ? 175 - textureGain : 219 - textureGain;
                if (y !== title.y)
                    title.y = y;
            });
        }
        this._content = this.tag('Content');
    }
    _active() {
        if (!this._modalData.shouldKeepFocus) {
            this._focusIndex = 0;
        }
    }
    _patchTemplate() {
        if (!this._type)
            return;
        const templateFunc = templateMap[this._type];
        if (!templateFunc)
            return;
        const { title, template } = templateFunc(this._modalData.stream, this._modalData.program, (recheck) => {
            var _a, _b;
            (_b = (_a = this._modalData).resolveCallback) === null || _b === void 0 ? void 0 : _b.call(_a, recheck);
            ModalManager.close();
        }, () => {
            ModalManager.open(ModalTypes.CREDITS_FLOW_MODAL, Object.assign(Object.assign({}, this._modalData), { shouldKeepFocus: true }), false);
        });
        this.title = title;
        this._content.patch(template);
        this._focusMap = [];
        this._content.childList.forEach((element) => {
            if (!element.skipFocus) {
                this._focusMap.push(element);
            }
        });
        this._focusIndex = 0;
        this._refocus();
    }
    _setTranslation() {
        this._patchTemplate();
    }
    _handleKey(e) {
        return true;
    }
    _handleBack() {
        this._modalData.rejectCallback();
    }
    _handleLeft() {
        if (this._focusIndex > 0) {
            this._focusIndex--;
        }
    }
    _handleRight() {
        if (this._focusIndex < this._focusMap.length - 1) {
            this._focusIndex++;
        }
    }
    _getFocused() {
        return this._focusMap[this._focusIndex];
    }
}
